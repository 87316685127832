document.addEventListener("turbolinks:load", () => {

  $('#proposal_attachments').on('cocoon:after-insert', function(e, insertedItem) {
    add_file_input();
  });

  $('#new_proposal_attachment').on('cocoon:after-insert', function(e, insertedItem) {
    add_file_input();
  });

  // adds js to dinamically created nested fields for panel usage
  $('#panel_usages')
    .on('cocoon:after-insert', function(e, insertedItem) {
      add_select2();
      $('.panel_usage_panel, .panel_usage_count').change(function() {
        update_target_power();
       }
      );
    })
    .on('cocoon:after-remove', function(e, removedItem) {
      update_target_power(); // removing a panel usage must fire target power recalculation
    });

  // adds js to dinamically created nested fields for inverter usage
  $('#inverter_usages').on('cocoon:after-insert', function(e, insertedItem) {
    add_select2();
  });

  // fires recalculation of targer power when any of its terms change
  $('.panel_usage_panel, .panel_usage_count').change(function() {
    update_target_power();
   });

  // fires recalculation of gross cost when any of its terms change
  $('.gross_cost_calculator').change(function() {
    update_gross_cost();
  });

  // fires recalculation of net cost when any of its terms change
  $('.net_cost_calculator').change(function() {
    update_net_cost();
  });

  // fires recalculation of gain value and gain rate when any of its terms change
  $('.gain_calculator').change(function() {
    update_gain();
  });

  // fires reloading of operating costs when any of its terms change
  $('.operating_costs_calculator').change(function() {
    update_operating_costs();
  });

  // fires reloading of taxes when any of its terms change
  $('.taxes_calculator').change(function() {
    calculate_taxes();
  });

  // fires reloading of taxes when any of its terms change
  $('#proposal_gain_value').change(function() {
    calculate_gain_rate();
  });

  // fires the logic for the selected invoice type when invoice type changes
  $('#proposal_invoice_type').change(function() {
    toggle_split_invoice_fields();
  });
  // forces to run the logic for the selected invoice type when page loads
  add_masks(); // needed due to a bug caused by the masks not being loaded before running gross cost calculation
  $('#proposal_invoice_type').trigger('change');
});

/*
Requests to the server the value of target power (in kWp) given the selected panels
and their respective amount.
The resulting value is set to #proposal_target_power field.
*/
window.update_target_power = function() {
  let panels = [];
  $('#panel_usages').find('.nested-fields:visible').each(function(){
    let usage = {}
    usage.panel_id = $(this).find('.panel_usage_panel').val();
    usage.count = $(this).find('.panel_usage_count').val();
    panels.push(usage);
  });

  if (panels.length > 0) {
    $.ajax({
      type: "GET",
      url: "/calculate_target_power",
      data: {panels}
    }).done(function (json) {
      $('#proposal_target_power').val(json['target_power']);
      update_operating_costs();
    });
  }
  else {
    $('#proposal_target_power').val(0.0);
    update_operating_costs();
  }
}

/*
Requests to the server the values of material cost and labour cost given the
selected operating costs table and the target power (in kWp).
The resulting values are set to #proposal_material_cost and #proposal_labour_cost fields.
*/
window.update_operating_costs = function(){
  let table_id = $('#proposal_operating_cost_table_id').val();
  let target_power = $('#proposal_target_power').val();
  $.ajax({
    type: "GET",
    url: "/operating_costs",
    data: {table_id: table_id, target_power: target_power}
  }).done(function (json) {
    let material_cost = 0.0;
    let labour_cost = 0.0;
    if (json['material_cost']) {
      material_cost = json['material_cost']['cents']/100
      labour_cost = json['labour_cost']['cents']/100
    }
    $('#proposal_material_cost').inputmask("setvalue", material_cost);
    $('#proposal_labour_cost').inputmask("setvalue", labour_cost);
    update_gross_cost();
  });
}

/*
Calculates the gross cost and sets the result to #proposal_gross_cost field.
Since all cost fields are masked in money format (using jquery-mask-plugin), we need
to unmask the values before using them.
*/
window.update_gross_cost = function(){
  let gross_cost = 0.0;
  if ($('#proposal_invoice_type').val() == 'single'){
    gross_cost += Number($('#proposal_kit_cost').inputmask('unmaskedvalue'));
  }
  else {
    gross_cost += Number($('#proposal_panel_cost').inputmask('unmaskedvalue'));
    gross_cost += Number($('#proposal_inverter_cost').inputmask('unmaskedvalue'));
    gross_cost += Number($('#proposal_structure_cost').inputmask('unmaskedvalue'));
  }
  gross_cost += Number($('#proposal_material_cost').inputmask('unmaskedvalue'));
  gross_cost += Number($('#proposal_labour_cost').inputmask('unmaskedvalue'));
  gross_cost += Number($('#proposal_engineering_services_cost').inputmask('unmaskedvalue'));
  gross_cost += Number($('#proposal_shipment_cost').inputmask('unmaskedvalue'));

  $('#proposal_gross_cost').inputmask("setvalue", gross_cost);
  $('#proposal_gross_cost').trigger('change');
}

/*
Calculates the net cost and sets the result to #proposal_net_cost field.
Since all cost fields are masked in money format (using jquery-mask-plugin), we need
to unmask the values before using them.
*/
window.update_net_cost = function(){
  let net_cost = 0.0;
  net_cost += Number($('#proposal_gross_cost').inputmask('unmaskedvalue'));
  net_cost += Number($('#proposal_material_tax_value').inputmask('unmaskedvalue'));
  net_cost += Number($('#proposal_service_tax_value').inputmask('unmaskedvalue'));
  $('#proposal_net_cost').inputmask("setvalue", net_cost);
  $('#proposal_net_cost').trigger('change');
}

/*
Calculates the gain and sets the results to #proposal_gain_rate and #proposal_gain_value fields.
Since all cost fields are masked in money format (using jquery-mask-plugin), we need
to unmask the values before using them.
*/
window.update_gain = function(){
  let gain_value = 0.0;
  gain_value += Number($('#proposal_sale_price').inputmask('unmaskedvalue'));
  gain_value -= Number($('#proposal_net_cost').inputmask('unmaskedvalue'));
  $('#proposal_gain_value').inputmask("setvalue", gain_value);
  $('#proposal_gain_value').trigger('change');
}

/*
Calculates the gross cost and sets the result to #proposal_gross_cost field.
Since all cost fields are masked in money format (using jquery-mask-plugin), we need
to unmask the values before using them.
*/
window.calculate_taxes = function(){
  $.ajax({
    type: "GET",
    url: "/calculate_taxes",
    data: {
      invoice_type: $('#proposal_invoice_type').val(),
      panel_cost: $('#proposal_panel_cost').inputmask('unmaskedvalue'),
      inverter_cost: $('#proposal_inverter_cost').inputmask('unmaskedvalue'),
      structure_cost: $('#proposal_structure_cost').inputmask('unmaskedvalue'),
      material_cost: $('#proposal_material_cost').inputmask('unmaskedvalue'),
      sale_price: $('#proposal_sale_price').inputmask('unmaskedvalue'),
      material_tax_rate: $('#proposal_material_tax_rate').val(),
      service_tax_rate: $('#proposal_service_tax_rate').val()
    }
  }).done(function (json) {
    $('#proposal_material_tax_value').inputmask("setvalue", json['material_tax_value']['cents']);
    $('#proposal_material_tax_value').trigger('change');
    $('#proposal_service_tax_value').inputmask("setvalue", json['service_tax_value']['cents']);
    $('#proposal_service_tax_value').trigger('change');
  });
}

window.calculate_gain_rate = function() {
  $.ajax({
    type: "GET",
    url: "/calculate_gain_rate",
    data: {
      net_cost: $('#proposal_net_cost').inputmask('unmaskedvalue'),
      sale_price: $('#proposal_sale_price').inputmask('unmaskedvalue')
    }
  }).done(function (json) {
    $('#proposal_gain_rate').val(json['gain_rate']);
  });
}

/*
Shows or hides costs and tax fields based in the selected invoice type.
Single invoice uses kit cost and doesn't pay service tax.
Split invoice uses (panel cost + inverter cost + structure cost) and pays service tax.
*/
window.toggle_split_invoice_fields = function() {
  if ($('#proposal_invoice_type').val() == 'single'){
    $('#proposal_panel_cost').inputmask("setvalue", "0");
    $('#proposal_inverter_cost').inputmask("setvalue", "0");
    $('#proposal_structure_cost').inputmask("setvalue", "0");
    $('#detailed_costs_row').hide();
    $('#proposal_service_tax_rate').inputmask("setvalue", "0");
    $('#proposal_service_tax_value').inputmask("setvalue", "0");
    $('#service_tax_row').hide();
    $('#kit_cost_row').show();
  }
  else{
    $('#detailed_costs_row').show();
    $('#service_tax_row').show();
    $('#proposal_kit_cost').inputmask("setvalue", "0");
    $('#kit_cost_row').hide();
  }
  update_gross_cost(); // since some field values were cleaned, gross cost must be recalculated
}

document.addEventListener("turbolinks:load", () => {

  $('#contract_periods').on('cocoon:after-insert', function(e, insertedItem) {
    add_file_input();
  });

  $('#maintenance_plan_client_selector').on('change', function (e) {
      let contracts = [];
      let consumer_units = [];

      let clientId = $(this).find('option:selected').val();
      if (clientId) {
        $.ajax({
          type: "GET",
          url: '/clients/' + clientId + '/contracts.json?include_equipment_label=true&include_roof_type_label=true'
        }).done(function(contracts) {
          $("#maintenance_plan_contract_selector").empty();
          $("#maintenance_plan_contract_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: contracts
          }).val($("#maintenance_plan_contract_selector").attr('val')).trigger('change');
        });

        $.ajax({
          type: "GET",
          url: '/clients/' + clientId + '/consumer_units.json'
        }).done(function(consumer_units) {
          $("#maintenance_plan_consumer_unit_selector").empty();
          $("#maintenance_plan_consumer_unit_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: consumer_units
          }).val($("#maintenance_plan_consumer_unit_selector").attr('val')).trigger('change');
        });
      }
      else{
        $("#maintenance_plan_contract_selector").empty();
        $("#maintenance_plan_contract_selector").val(null).trigger('change');
        $("#maintenance_plan_consumer_unit_selector").empty();
        $("#maintenance_plan_consumer_unit_selector").val(null).trigger('change');
      }
  });

  $('#maintenance_plan_client_selector').trigger('change');

});

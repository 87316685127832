
document.addEventListener("turbolinks:load", () => {

  $("input[name='client[person_type]']").change(function() {
    toggle_person_type_fields($(this).val());
  });
  toggle_person_type_fields($("input[name='client[person_type]']:checked").val());
});

window.toggle_person_type_fields = function(person_type) {
  if (person_type == 'physical') {

    $("label[for='client_name']").html('Nome completo <abbr title="obrigatório">*</abbr>');
    $("label[for='client_cpf_cnpj']").html('CPF <abbr title="obrigatório">*</abbr>');
    $('#client_cpf_cnpj').mask("999.999.999-99");

    $('.physical_person_field').show();
    $('.legal_person_field').hide();

  } else {

    $("label[for='client_name']").html('Razão social <abbr title="obrigatório">*</abbr>');
    $("label[for='client_cpf_cnpj']").html('CNPJ <abbr title="obrigatório">*</abbr>');
    $("label[for='client_legal_representative_cpf']").html('CPF do Representante Legal <abbr title="obrigatório">*</abbr>');
    $("label[for='client_legal_representative']").html('Representante Legal <abbr title="obrigatório">*</abbr>');

    $('#client_cpf_cnpj').mask("99.999.999/9999-99");
    $('#client_legal_representative_cpf').mask("999.999.999-99");
    $('#client_birthday').val('');
    $('#client_rg').val('');
    $('#client_expedition_date').val('');

    $('.physical_person_field').hide();
    $('.legal_person_field').show();
  }
}

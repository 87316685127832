// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
Rails.start()
Turbolinks.start()
ActiveStorage.start()

const images = require.context('../images', true)

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'bootstrap';

import 'admin-lte';

import "@nathanvda/cocoon";

import 'select2/dist/js/select2.full';

import 'jquery-mask-plugin';
import 'inputmask/dist/jquery.inputmask.js';

import 'bootstrap-fileinput';
import 'bootstrap-fileinput/js/locales/pt-BR';
import 'bootstrap-fileinput/themes/fas/theme';

import "selectize";

import "@fortawesome/fontawesome-free/css/all.css";

import '../stylesheets/application';

import './zutils'
import './clients'
import './constructions'
import './maintenance_plans'
import './operating_cost_tables'
import './preliminary_visits'
import './proposals'
import './projects'
import './purchases'
import './quotations'
import './tickets'

$(document).on('turbolinks:before-cache', function() {
  // this approach corrects the select 2 to be duplicated when clicking the back button.
  $("select.select2-hidden-accessible").select2('destroy');
});

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  // Initialize select2
  add_select2();
  addSelect2Cidades();

  // Initialize custom file input
  add_file_input();

  // Initialize masks
  add_masks();
  add_money_masks();

});

window.add_select2 = function() {
  //Initialize Select2 Elements
  $('.select2').select2({
    allowClear: true,
    width: '100%',
    language: 'pt-BR',
    placeholder: "Selecione..."
  });

  //Initialize Select2 Elements with bootstrap4 theme
  $('.select2bs4').select2({
    theme: 'bootstrap4',
    allowClear: true,
    width: '100%',
    language: 'pt-BR',
    placeholder: "Selecione..."
  });
}

window.addSelect2Cidades = function() {
    $(".select2_cidades").select2({
        allowClear: true,
        width: '100%',
        language: "pt-BR",
        placeholder: "Buscar Cidade",
        minimumInputLength: 3,
        ajax: {
            dataType: 'json',
            url: "/search_cities",
            processResults: function(data) {
                return {
                    results: data
                };
            }
        }
    });
}

window.add_file_input = function() {
  $(".bootstrap_file_input").each(function() {
    var input = $(this);
    input.fileinput({
        language: 'pt-BR',
        // allowedFileExtensions: ['pdf'],
        'previewFileType': 'any',
        initialPreviewAsData: true,
        initialPreview: input.data('url'),
        showPreview: input.data('show_preview'),
        initialPreviewFileType: 'pdf',
        initialPreviewConfig: [
            { caption: input.data('filename'), height: 'auto', widht: '120px', showDelete: false, showDrag: false, key: 1 }
        ],
        showRemove: input.data('show_remove'),
        showClose: true,
        showUpload: false,
        showCancel: false
    });
  });
}

window.add_masks = function() {
    $('.masked_cpf').each(function(){
      $(this).mask("999.999.999-99");
    });

    $('.masked_cnpj').each(function(){
      $(this).mask("99.999.999/9999-99");
    });

    $('.masked_phone').each(function() {
      $(this).mask("(00) 00000-0000");
    });

    $('.masked_cep').each(function() {
      $(this).mask("00000-000");
    });

    $('.masked_integer').each(function() {
      $(this).mask("#");
    });

    // $('.masked_money').each(function(){
    //   $(this).mask("#.##0,00", {reverse: true});
    // });
}

window.add_money_masks = function(){
  $('.masked_money').each(function(){
    $(this).inputmask('currency',{"autoUnmask": true,
            radixPoint:",",
            groupSeparator: ".",
            allowMinus: true,
            prefix: '',
            digits: 2,
            digitsOptional: false,
            rightAlign: false,
            unmaskAsNumber: true
    });
  });
}

document.addEventListener("turbolinks:load", () => {

  $('#construction_images').on('cocoon:after-insert', function(e, insertedItem) {
    add_file_input();
  });

  $('#construction_client_selector').on('change', function (e) {
      let contracts = [];
      let clientId = $(this).find('option:selected').val();
      if (clientId) {
        $.ajax({
          type: "GET",
          url: '/clients/' + clientId + '/contracts.json?include_equipment_label=true&include_roof_type_label=true'
        }).done(function(contracts) {
          $("#construction_contract_selector").empty();
          $("#construction_contract_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: [{"id": '', "text": ""}].concat(contracts)
          }).val($("#construction_contract_selector").attr('val')).trigger('change');
        });
      }
      else{
        $("#construction_contract_selector").empty();
        $("#construction_contract_selector").val(null).trigger('change');
      }
  });

  $('#construction_client_selector').trigger('change');

  $('#construction_contract_selector').on('change', function (e) {
      let projects = [];
      let contractId = $(this).find('option:selected').val();
      if (contractId) {
        $.ajax({
          type: "GET",
          url: '/contracts/' + contractId + '/projects.json'
        }).done(function(projects) {
          $("#construction_project_selector").empty();
          $("#construction_project_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: [{"id": '', "text": ""}].concat(projects)
          }).val($("#construction_project_selector").attr('val')).trigger('change');
        });
      }
      else{
        $("#construction_project_selector").empty();
        $("#construction_project_selector").val(null).trigger('change');
      }
  });

  $('#construction_project_selector').on('change', function (e) {
      let revisions = [];
      let projectId = $(this).find('option:selected').val();
      if (projectId) {
        $.ajax({
          type: "GET",
          url: '/projects/' + projectId + '/approved_revisions.json'
        }).done(function(revisions) {
          $("#construction_revision_selector").empty();
          $("#construction_revision_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: [{"id": '', "text": ""}].concat(revisions)
          }).val($("#construction_revision_selector").attr('val')).trigger('change');
        });
      }
      else{
        $("#construction_revision_selector").empty();
        $("#construction_revision_selector").val(null).trigger('change');
      }
  });

});

document.addEventListener("turbolinks:load", () => {

  $('#purchase_client_selector').on('change', function (e) {
      let contracts = [];
      let clientId = $(this).find('option:selected').val();
      if (clientId) {
        $.ajax({
          type: "GET",
          url: '/clients/' + clientId + '/contracts.json?include_equipment_label=true&include_roof_type_label=true'
        }).done(function(contracts) {
          $("#purchase_contract_selector").empty();
          $("#purchase_contract_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: contracts
          }).val($("#purchase_contract_selector").attr('val')).trigger('change');
        });
      }
      else{
        $("#purchase_contract_selector").empty();
        $("#purchase_contract_selector").val(null).trigger('change');
      }
  });

  $('#purchase_client_selector').trigger('change');
});

document.addEventListener("turbolinks:load", () => {

  $('#project_client_selector').on('change', function (e) {
      let contracts = [];
      let clientId = $(this).find('option:selected').val();
      if (clientId) {
        $.ajax({
          type: "GET",
          url: '/clients/' + clientId + '/contracts.json?include_equipment_label=true&include_roof_type_label=true'
        }).done(function(contracts) {
          $("#project_contract_selector").empty();
          $("#project_contract_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: contracts
          }).val($("#project_contract_selector").attr('val')).trigger('change');
        });
      }
      else{
        $("#project_contract_selector").empty();
        $("#project_contract_selector").val(null).trigger('change');
      }
  });

  $('#project_client_selector').trigger('change');

  $('#project_contract_selector').on('change', function (e) {
      let consumer_units = [];
      let contractId = $(this).find('option:selected').val();
      if (contractId) {
        $.ajax({
          type: "GET",
          url: '/contracts/' + contractId + '/contract_consumer_units.json'
        }).done(function(consumer_units) {
          $("#project_contract_consumer_unit_selector").empty();
          $("#project_contract_consumer_unit_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: consumer_units
          }).val($("#project_contract_consumer_unit_selector").attr('val').split(/(\s+)/)).trigger('change');
        });
      }
      else{
        $("#project_contract_consumer_unit_selector").empty();
        $("#project_contract_consumer_unit_selector").val(null).trigger('change');
      }
  });

});

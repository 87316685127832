require("selectize")
// require("packs/language")

document.addEventListener("turbolinks:load", () => {


    $(".selectize_with_add").each(function(i, item) {
        var selectizeCallback = null;

        $(item).selectize({
            create: function(input, callback) {
                selectizeCallback = callback;

                $("." + $(item).data('name') + "-modal").modal();
                $("#" + $(item).data('name') + "_name").val(input);
            }
        });

        $("." + $(item).data('name') + "-modal").on("hide.bs.modal", function(e) {
            if (selectizeCallback != null) {
                selectizeCallback();
                selecitzeCallback = null;
            }

            $("." + $(item).data('name') + "-modal form").trigger("reset");
            $("." + $(item).data('name') + "-modal form select").val(null).trigger('change');
            // $.rails.enableFormElements($("." + $(item).data('name') + "-modal form"));
            var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
            $(selectors).each(function() {
                $.rails.enableElement(this);
            })
        });

        $("." + $(item).data('name') + "-modal form").on("submit", function(e) {
            e.preventDefault();

            $("." + $(item).data('name') + "-modal form").find('.has-error').removeClass('has-error');
            $("." + $(item).data('name') + "-modal form").find('.help-block').text("");
            $.ajax({
                method: "POST",
                dataType: "json",
                url: $(this).attr("action"),
                data: $(this).serialize(),
                success: function(response) {
                    selectizeCallback({value: response.id, text: response.name});
                    selectizeCallback = null;

                    $("." + $(item).data('name') + "-modal").modal('toggle');
                },
                error: function(response) {
                    $.each(response.responseJSON, function(key, value) {
                        $("." + $(item).data('name') + "-modal form").find("." + $(item).data('model') + '_' + key).addClass('has-error');
                        if (!($("." + $(item).data('name') + "-modal form" + " ." + $(item).data('model') + '_' + key).find('.help-block').length)) {
                            $("." + $(item).data('name') + "-modal form").find("." + $(item).data('model') + '_' + key).append("<p class='help-block'></p>");
                        }
                        $("." + $(item).data('name') + "-modal form").find("." + $(item).data('model') + '_' + key).find('.help-block').text(value);
                    });
                    // $.rails.enableFormElements($("." + $(item).data('name') + "-modal form" + ""));
                    var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
                    $(selectors).each(function() {
                        $.rails.enableElement(this);
                    })
                }
            });
        });
    });

    $(".selectize_with_add_without_modal").each(function(i, item) {
      $(item).selectize({
        create: function(input, callback) {
          $(item).parent().removeClass('has-error');
          $(item).parent().find('.help-block').text("");
          selectizeCallback = callback;
          data = {};
          data[$(item).data('model')] = {};
          data[$(item).data('model')][$(item).data('field')] = input;
          $.ajax({
            method: "POST",
            dataType: "json",
            url: "/" + $(item).data('pluralized'),
            data: data,
            success: function(response) {
              selectizeCallback({value: response.id, text: response.name});
            },
            error: function(response) {
              $(item).parent().addClass('has-error');
              if (!( $(item).parent().find('.help-block').length)) {
                  $(item).parent().append("<p class='help-block'></p>");
              }
              $(item).parent().find('.help-block').text("Valor já existente: " + input);
              selectizeCallback();
            }
          });
          selectizeCallback = callback;
        }
      });
    });
  });

document.addEventListener("turbolinks:load", () => {


  $('#ticket_client_selector').on('change', function (e) {
      let maintenance_plans = [];

      let clientId = $(this).find('option:selected').val();
      if (clientId) {

        $.ajax({
          type: "GET",
          url: '/clients/' + clientId + '/maintenance_plans.json'
        }).done(function(maintenance_plans) {
          $("#ticket_maintenance_plan_selector").empty();
          $("#ticket_maintenance_plan_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: maintenance_plans
          }).val($("#ticket_maintenance_plan_selector").attr('val')).trigger('change');
        });
      }
      else{
        $("#ticket_maintenance_plan_selector").empty();
        $("#ticket_maintenance_plan_selector").val(null).trigger('change');
      }
  });

  $('#ticket_client_selector').trigger('change');

});

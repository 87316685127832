document.addEventListener("turbolinks:load", () => {

  $('#energy_bills').on('cocoon:after-insert', function(e, insertedItem) {
    add_file_input();
  });

  $('#simulation_images').on('cocoon:after-insert', function(e, insertedItem) {
    add_file_input();
  });

  $('#contract_client_selector').on('change', function (e) {
      let consumer_units = [];
      let clientId = $(this).find('option:selected').val();
      if (clientId) {
        $.ajax({
          type: "GET",
          url: '/clients/' + clientId + '/generator_consumer_units.json'
        }).done(function(consumer_units) {
          let consumerUnitId = $("#contract_consumer_unit_selector").val();
          $("#contract_consumer_unit_selector").empty();
          $("#contract_consumer_unit_selector").select2({
            allowClear: true,
            width: '100%',
            language: 'pt-BR',
            placeholder: "Selecione...",
            data: consumer_units
          }).val($("#contract_consumer_unit_selector").attr('val').split(/(\s+)/)).trigger('change');
        });
      }
      else{
        $("#contract_consumer_unit_selector").empty();
        $("#contract_consumer_unit_selector").val(null).trigger('change');
      }
  });

  $('#contract_client_selector').trigger('change');

});
